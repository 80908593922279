<template>
  <div class="container">
    <div class="columns">
      <div class="column content login-box card is-offset-1 is-10 content">
        <div class="section">
          <h2>Welcome to Vendor Payments</h2>
          <p>Vendor Payments provides a secure way for vendors and foster parents to manage payments from the City.</p>
          <p>
            You can use this website to find information about:
            <ul>
              <li><router-link to="/vendor">past payments.</router-link></li>
              <li><router-link to="/vendor/own/pending">payments in progress.</router-link></li>
              <li><router-link to="/departments/department-finance">City department contacts.</router-link></li>
            </ul>
          </p>
          <p>
            You can also:
            <ul>
              <li><router-link to="/ach/form">enroll in or change ACH payments.</router-link></li>
              <li><router-link to="/profile">update your vendor profile.</router-link></li>
              <li><router-link to="/invoice/form">submit an invoice to the City.</router-link></li>
            </ul>
          </p>
          <p>
            If you have any questions about payments, invoices, or lost checks, call or email your City department contact.
          </p>

          <p class="is-justify-content-flex-end buttons">
            <router-link
              to="/vendor"
              class="button is-primary"
            >
              View Payments
            </router-link>

            <router-link
              to="/invoice/form"
              class="button is-primary"
            >
              Submit an Invoice
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VendorLanding',
  computed: {
    achFormEnabled() {
      let value;
      if (process.env.VUE_APP_ACH_FORM_ENABLED === 'true') {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
  },
};

</script>
